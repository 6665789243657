import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

const IntegratedBanks = () => {
  const data = useStaticQuery(graphql`{
    aib:
    file(relativePath: {eq: "logos/bank/aib.png"}) {
      childImageSharp {
        gatsbyImageData(
          width: 150
          height: 150
          transformOptions: {cropFocus: CENTER, fit: INSIDE}
          layout: FIXED,
          quality: 100
        )
      }
    }

    aub:
    file(relativePath: {eq: "logos/bank/aub.png"}) {
      childImageSharp {
        gatsbyImageData(
          width: 150
          height: 150
          transformOptions: {cropFocus: CENTER, fit: INSIDE}
          layout: FIXED,
          quality: 100
        )
      }
    }

    dab:
    file(relativePath: {eq: "logos/bank/dab.png"}) {
      childImageSharp {
        gatsbyImageData(
          width: 150
          height: 150
          transformOptions: {cropFocus: CENTER, fit: INSIDE}
          layout: FIXED,
          quality: 100
        )
      }
    }

    azb:
    file(relativePath: {eq: "logos/bank/azb.png"}) {
      childImageSharp {
        gatsbyImageData(
          width: 150
          height: 150
          transformOptions: {cropFocus: CENTER, fit: INSIDE}
          layout: FIXED,
          quality: 100
        )
      }
    }

    bma:
    file(relativePath: {eq: "logos/bank/bma.png"}) {
      childImageSharp {
        gatsbyImageData(
          width: 150
          height: 150
          transformOptions: {cropFocus: CENTER, fit: INSIDE}
          layout: FIXED,
          quality: 100
        )
      }
    }

    dab:
    file(relativePath: {eq: "logos/bank/dab.png"}) {
      childImageSharp {
        gatsbyImageData(
          width: 150
          height: 150
          transformOptions: {cropFocus: CENTER, fit: INSIDE}
          layout: FIXED,
          quality: 100
        )
      }
    }

    fmfb:
    file(relativePath: {eq: "logos/bank/fmfb.png"}) {
      childImageSharp {
        gatsbyImageData(
          width: 150
          height: 150
          transformOptions: {cropFocus: CENTER, fit: INSIDE}
          layout: FIXED,
          quality: 100
        )
      }
    }

    gb:
    file(relativePath: {eq: "logos/bank/gb.png"}) {
      childImageSharp {
        gatsbyImageData(
          width: 150
          height: 150
          transformOptions: {cropFocus: CENTER, fit: INSIDE}
          layout: FIXED,
          quality: 100
        )
      }
    }

    iba:
    file(relativePath: {eq: "logos/bank/iba.png"}) {
      childImageSharp {
        gatsbyImageData(
          width: 150
          height: 150
          transformOptions: {cropFocus: CENTER, fit: INSIDE}
          layout: FIXED,
          quality: 100
        )
      }
    }

    mb:
    file(relativePath: {eq: "logos/bank/mb.png"}) {
      childImageSharp {
        gatsbyImageData(
          width: 150
          height: 150
          transformOptions: {cropFocus: CENTER, fit: INSIDE}
          layout: FIXED,
          quality: 100
        )
      }
    }

    nkb:
    file(relativePath: {eq: "logos/bank/nkb.png"}) {
      childImageSharp {
        gatsbyImageData(
          width: 150
          height: 150
          transformOptions: {cropFocus: CENTER, fit: INSIDE}
          layout: FIXED,
          quality: 100
        )
      }
    }

    pb:
    file(relativePath: {eq: "logos/bank/pb.png"}) {
      childImageSharp {
        gatsbyImageData(
          width: 150
          height: 150
          transformOptions: {cropFocus: CENTER, fit: INSIDE}
          layout: FIXED,
          quality: 100
        )
      }
    }

    hesabpay:
    file(relativePath: {eq: "logos/pi/hesabpay.png"}) {
      childImageSharp {
        gatsbyImageData(
          width: 150
          height: 150
          transformOptions: {cropFocus: CENTER, fit: INSIDE}
          layout: FIXED,
          quality: 100
        )
      }
    }

    fastpay:
    file(relativePath: {eq: "logos/pi/fastpay.png"}) {
      childImageSharp {
        gatsbyImageData(
          width: 150
          height: 150
          transformOptions: {cropFocus: CENTER, fit: INSIDE}
          layout: FIXED,
          quality: 100
        )
      }
    }

    rtc:
    file(relativePath: {eq: "logos/pi/rtc.png"}) {
      childImageSharp {
        gatsbyImageData(
          width: 150
          height: 150
          transformOptions: {cropFocus: CENTER, fit: INSIDE}
          layout: FIXED,
          quality: 100
        )
      }
    }

    tamaas:
    file(relativePath: {eq: "logos/pi/tamaas.png"}) {
      childImageSharp {
        gatsbyImageData(
          width: 150
          height: 150
          transformOptions: {cropFocus: CENTER, fit: INSIDE}
          layout: FIXED,
          quality: 100
        )
      }
    }

    finca:
    file(relativePath: {eq: "logos/mfi/finca.png"}) {
      childImageSharp {
        gatsbyImageData(
          width: 150
          height: 150
          transformOptions: {cropFocus: CENTER, fit: INSIDE}
          layout: FIXED,
          quality: 100
        )
      }
    }

    mutahid:
    file(relativePath: {eq: "logos/mfi/mutahid.png"}) {
      childImageSharp {
        gatsbyImageData(
          width: 150
          height: 150
          transformOptions: {cropFocus: CENTER, fit: INSIDE}
          layout: FIXED,
          quality: 100
        )
      }
    }

    oxus:
    file(relativePath: {eq: "logos/mfi/oxus.png"}) {
      childImageSharp {
        gatsbyImageData(
          width: 150
          height: 150
          transformOptions: {cropFocus: CENTER, fit: INSIDE}
          layout: FIXED,
          quality: 100
        )
      }
    }

    mhawala:
    file(relativePath: {eq: "logos/emi/mhawala.png"}) {
      childImageSharp {
        gatsbyImageData(
          width: 150
          height: 150
          transformOptions: {cropFocus: CENTER, fit: INSIDE}
          layout: FIXED,
          quality: 100
        )
      }
    }

    mpaisa:
    file(relativePath: {eq: "logos/emi/mpaisa.png"}) {
      childImageSharp {
        gatsbyImageData(
          width: 150
          height: 150
          transformOptions: {cropFocus: CENTER, fit: INSIDE}
          layout: FIXED,
          quality: 100
        )
      }
    }

    mymoney:
    file(relativePath: {eq: "logos/emi/mymoney.png"}) {
      childImageSharp {
        gatsbyImageData(
          width: 150
          height: 150
          transformOptions: {cropFocus: CENTER, fit: INSIDE}
          layout: FIXED,
          quality: 100
        )
      }
    }

    momo:
    file(relativePath: {eq: "logos/emi/momo.jpg"}) {
      childImageSharp {
        gatsbyImageData(
          width: 150
          height: 150
          transformOptions: {cropFocus: CENTER, fit: INSIDE}
          layout: FIXED,
          quality: 100
        )
      }
    }

    ard:
    file(relativePath: {eq: "logos/vas/ard.png"}) {
      childImageSharp {
        gatsbyImageData(
          width: 150
          height: 150
          transformOptions: {cropFocus: CENTER, fit: INSIDE}
          layout: FIXED,
          quality: 100
        )
      }
    }

    asycuda:
    file(relativePath: {eq: "logos/vas/asycuda.png"}) {
      childImageSharp {
        gatsbyImageData(
          width: 150
          height: 150
          transformOptions: {cropFocus: CENTER, fit: INSIDE}
          layout: FIXED,
          quality: 100
        )
      }
    }
  }
  `);

  return (
    <div className="ui centered six column grid container" style={{marginTop: '3rem', marginBottom: '3rem'}}>
      <div className="column member-logo">
        <GatsbyImage image={data.aib.childImageSharp.gatsbyImageData} alt="AIB"/>
      </div>
      <div className="column member-logo">
        <GatsbyImage image={data.aub.childImageSharp.gatsbyImageData} alt="AUB"/>
      </div>
      <div className="column member-logo">
        <GatsbyImage image={data.azb.childImageSharp.gatsbyImageData} alt="Azizi"/>
      </div>
      <div className="column member-logo">
        <GatsbyImage image={data.bma.childImageSharp.gatsbyImageData} alt="BMA"/>
      </div>
      <div className="column member-logo">
        <GatsbyImage image={data.dab.childImageSharp.gatsbyImageData} alt="DAB"/>
      </div>
      <div className="column member-logo">
        <GatsbyImage image={data.fmfb.childImageSharp.gatsbyImageData} alt="FMFB"/>
      </div>
      <div className="column member-logo">
        <GatsbyImage image={data.gb.childImageSharp.gatsbyImageData} alt="GB" objectPosition="100%"/>
      </div>
      <div className="column member-logo">
        <GatsbyImage image={data.iba.childImageSharp.gatsbyImageData} alt="IBA"/>
      </div>
      <div className="column member-logo">
        <GatsbyImage image={data.mb.childImageSharp.gatsbyImageData} alt="Maiwand Bank"/>
      </div>
      <div className="column member-logo">
        <GatsbyImage image={data.nkb.childImageSharp.gatsbyImageData} alt="NKB"/>
      </div>
      <div className="column member-logo">
        <GatsbyImage image={data.pb.childImageSharp.gatsbyImageData} alt="PB" objectFit="cover" objectPosition="100%"/>
      </div>

      <br/>

      <div className="column member-logo">
        <GatsbyImage image={data.hesabpay.childImageSharp.gatsbyImageData} alt="AHG"/>
      </div>
      <div className="column member-logo">
        <GatsbyImage image={data.rtc.childImageSharp.gatsbyImageData} alt="AHG"/>
      </div>
      <div className="column member-logo">
        <GatsbyImage image={data.fastpay.childImageSharp.gatsbyImageData} alt="AHG"/>
      </div>
      <div className="column member-logo">
        <GatsbyImage image={data.tamaas.childImageSharp.gatsbyImageData} alt="AHG"/>
      </div>

      <br/>

      <div className="column member-logo">
        <GatsbyImage image={data.mhawala.childImageSharp.gatsbyImageData} alt="mHawala"/>
      </div>
      <div className="column member-logo">
        <GatsbyImage image={data.mymoney.childImageSharp.gatsbyImageData} alt="MyMoney"/>
      </div>
      <div className="column member-logo">
        <GatsbyImage image={data.momo.childImageSharp.gatsbyImageData} alt="MoMo"/>
      </div>
      <div className="column member-logo">
        <GatsbyImage image={data.mpaisa.childImageSharp.gatsbyImageData} alt="mPaisa"/>
      </div>

      <br/>

      <div className="column member-logo">
        <GatsbyImage image={data.finca.childImageSharp.gatsbyImageData} alt="FINCA"/>
      </div>
      <div className="column member-logo">
        <GatsbyImage image={data.mutahid.childImageSharp.gatsbyImageData} alt="Mutahid"/>
      </div>
      <div className="column member-logo">
        <GatsbyImage image={data.oxus.childImageSharp.gatsbyImageData} alt="OXUS"/>
      </div>

      <br/>

      <div className="column member-logo">
        <GatsbyImage image={data.ard.childImageSharp.gatsbyImageData} alt="ARD"/>
      </div>
      <div className="column member-logo">
        <GatsbyImage image={data.asycuda.childImageSharp.gatsbyImageData} alt="ASYCUDA"/>
      </div>
    </div>
  );
};

export default IntegratedBanks;