import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import * as React from "react";
import commonMessageValues from '../templates/formatjs-message-values';

const DomesticCardScheme = (): JSX.Element => {
  const messages = useIntl().messages;
  return (
    <div id="homeBackgroundImage" style={{height: '400px', paddingTop: '10%'}}>
      <h2 className="ui center aligned all-caps inverted header">{messages.domestic_card_scheme}</h2>
      <div className="ui container">
        <FormattedMessage id="message_domestic_card_scheme" values={commonMessageValues}/>
      </div>
    </div>
  );
};

export default DomesticCardScheme;