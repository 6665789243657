import * as React from 'react';
import Header from './header';
import Footer from './footer';
import { useIntl } from 'gatsby-plugin-react-intl';

const FreeLayout = ({children}: any): JSX.Element => {
  const intl = useIntl();

  return (
    <div id="top-container" className={(intl.locale === "en" ? "text-ltr" : "text-rtl")}>
      <Header/>
      {children}
      <Footer/>
    </div>
  );
};

export default FreeLayout;