import * as React from 'react';
import FreeLayout from '../components/free-layout';
import AboutUs from "../components/about-us";
import DomesticCardScheme from "../components/domestic-card-scheme";
import IntegratedBanks from "../components/integrated-banks";
import SlideShow from "../components/slideshow";
import { useIntl } from "gatsby-plugin-react-intl";
import { Helmet } from 'react-helmet';

const IndexPage = () => {
  const messages = useIntl().messages;

  return (
    <FreeLayout>
      <Helmet htmlAttributes={{lang: 'en'}}>
        <meta charSet="utf-8"/>
        <title>{messages.aps}</title>
      </Helmet>
      <SlideShow/>
      <AboutUs/>
      <DomesticCardScheme/>
      <IntegratedBanks/>
    </FreeLayout>
  );
};

export default IndexPage;