import * as React from "react";
import { useIntl } from "gatsby-plugin-react-intl";
import { graphql, useStaticQuery } from "gatsby";
import { Carousel } from "react-bootstrap";
import { GatsbyImage } from "gatsby-plugin-image";
import '../styles/index.css';

const SlideShow = (): JSX.Element => {
  const messages = useIntl().messages;
  const data = useStaticQuery(graphql`{
    slide1: file(relativePath: {eq: "slide1.jpg"}) {
      childImageSharp {
        gatsbyImageData(
          height: 400
          quality: 70
          aspectRatio: 3
          transformOptions: {cropFocus: ATTENTION, fit: FILL }
          layout: FULL_WIDTH
        )
      }
    }
    slide2: file(relativePath: {eq: "slide2.jpg"}) {
      childImageSharp {
        gatsbyImageData(
          height: 400
          quality: 70
          aspectRatio: 3
          transformOptions: {cropFocus: ATTENTION, fit: FILL}
          layout: FULL_WIDTH
        )
      }
    }
    slide3: file(relativePath: {eq: "slide3.jpg"}) {
      childImageSharp {
        gatsbyImageData(
          height: 400
          quality: 70
          aspectRatio: 3
          transformOptions: {cropFocus: ATTENTION, fit: FILL}
          layout: FULL_WIDTH
        )
      }
    }
    slide4: file(relativePath: {eq: "slide4.jpg"}) {
      childImageSharp {
        gatsbyImageData(
          height: 400
          quality: 70
          aspectRatio: 3
          transformOptions: {cropFocus: ATTENTION, fit: FILL}
          layout: FULL_WIDTH
        )
      }
    }
  }
  `);

  return (
    <Carousel interval={5000}>
      <Carousel.Item>
        <GatsbyImage image={data.slide1.childImageSharp.gatsbyImageData} alt="logo"/>

        <Carousel.Caption>
          <h2>{messages.aps}</h2>
          <h3>{messages.promoting_financial_inclusion}</h3>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <GatsbyImage image={data.slide2.childImageSharp.gatsbyImageData} alt="logo"/>

        <Carousel.Caption>
          <h2>{messages.providing}</h2>
          <h3>{messages.epayment_service}</h3>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <GatsbyImage image={data.slide3.childImageSharp.gatsbyImageData} alt="logo"/>

        <Carousel.Caption className="highlight">
          <h2>{messages.access_to}</h2>
          <h3>{messages.international_payment_gateways}</h3>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <GatsbyImage image={data.slide4.childImageSharp.gatsbyImageData} alt="logo"/>

        <Carousel.Caption className="highlight">
          <h2>{messages.pay_smart_pay_with_card}</h2>
          <h3>{messages.simple_secure_and_cost_effective}</h3>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
};

export default SlideShow;