import { FormattedNumber, useIntl } from "gatsby-plugin-react-intl";
import * as React from "react";
import { Link } from "gatsby";

const AboutUs = (): JSX.Element => {
  const intl = useIntl();
  const messages = useIntl().messages;

  const DetailsButton = (props: any) => {
    return (
      <Link to={`${props.url}`}>
        <button className="ui blue center aligned all-caps button" style={{marginTop: '2rem', marginBottom: '2rem'}}>{messages.get_details}</button>
      </Link>
    );
  };

  return (
    <div className={"ui container " + (intl.locale === "en" ? "text-ltr" : "text-rtl")}>
      <div className="ui vertical segmentgatsby ">
        <h1 className="ui home header all-caps extra vertical margin">{messages.about_us}</h1>
        <p>{messages.message_about_us}</p>
        <DetailsButton url="/about"/>
      </div>
      <div className="ui vertical segment" style={{paddingBottom: '3.5em'}}>
        <h1 className="ui center aligned all-caps header" style={{marginBottom: '4rem'}}>
          {messages.statistics_of_month_year}&nbsp;
          <FormattedNumber useGrouping={false} value={2021}/>
        </h1>

        <div className="ui four column grid">
          <div className="center aligned column">
            <h2><FormattedNumber value={524455}/></h2>
            <div style={{marginTop: '2.1em'}}>{messages.international_cards_in_network}</div>
          </div>

          <div className="center aligned column">
            <h2>
              <FormattedNumber value={520091}/>
            </h2>
            <div style={{marginTop: '2.1em'}}>{messages.domestic_card_issuance}</div>
          </div>

          <div className="center aligned column">
            <h2>
              <FormattedNumber value={390}/>
            </h2>
            <div style={{marginTop: '2.1em'}}>{messages.atm_count}</div>
          </div>

          <div className="center aligned column">
            <h2>
              <FormattedNumber value={11}/>
            </h2>
            <div style={{marginTop: '2.1em'}} id="">{messages.connected_banks}</div>
          </div>
        </div>
      </div>

      <div className="ui vertical segment">
        <h1 className="ui center aligned home all-caps header"
            style={{fontSize: '2.5rem', marginTop: '2.4rem'}}>{messages.our_services}</h1>
        <div className="ui two column grid">
          <div className="row">
            <div className="center aligned column">
              <i className="huge blue icon university" style={{marginTop: '3.5rem', marginBottom: '.6rem'}}/>
              <h2 className="ui home all-caps header">{messages.banking_and_microfinance}</h2>
              <div className="ui list-group center aligned">
                <div className="list-group-item borderless">{messages.domestic_card}</div>
                <div className="list-group-item borderless">{messages.international_card_scheme}</div>
                <div className="list-group-item borderless">{messages.merchant_driving_and_monitoring}</div>
                <div className="list-group-item borderless">{messages.sms_banking}</div>
                <div className="list-group-item borderless">{messages.mobile_banking}</div>
                <div className="list-group-item borderless">{messages.ivr_banking}</div>
              </div>
              <DetailsButton url="/banks-micro-finance"/>
            </div>

            <div className="center aligned column">
              <i className="huge blue icon mobile alternate" style={{marginTop: '3.5rem', marginBottom: '.6rem'}}/>
              <h2 className="ui home all-caps header">{messages.electronic_money_institutions}</h2>

              <div className="ui list-group center aligned">
                <div className="list-group-item borderless">{messages.interoperability}</div>
                <div className="list-group-item borderless">{messages.prepaid_cards}</div>
              </div>

              <DetailsButton url="/interoperability"/>
            </div>
          </div>

          <div className="ui divider"/>

          <div className="row">
            <div className="center aligned column">
              <i className="huge blue icon city" style={{marginTop: '2.4rem'}}/>
              <h2 className="ui home all-caps header">{messages.public_services}</h2>
              <div className="ui list-group center aligned">
                <div className="list-group-item borderless">{messages.customs_tax_payment}</div>
                <div className="list-group-item borderless">{messages.income_tax_payment}</div>
                <div className="list-group-item borderless">{messages.breshna_payment}</div>
                <div className="list-group-item borderless">{messages.other_public_services_payment}</div>
              </div>
              <DetailsButton url="/public-services"/>
            </div>

            <div className="center aligned column">
              <i className="huge blue icon credit card outline" style={{marginTop: '2.4rem'}}/>
              <h2 className="ui home all-caps header">{messages.payment_institutions}</h2>
              <div className="center aligned list-group">
                <div className="list-group-item borderless">{messages.atm_driving_and_monitoring}</div>
                <div className="list-group-item borderless">{messages.prepaid_cards}</div>
              </div>

              <DetailsButton url="/payment-service-providers/payment-institutions"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AboutUs;